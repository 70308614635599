import getPositionVo from "@/lib/data-service/haolv-default/consumer_web_policyConfig_getPositionVo";
import addTravelPolicy from "@/lib/data-service/haolv-default/consumer_web_policyConfig_addTravelPolicy";
import updateTravelPolicy from "@/lib/data-service/haolv-default/consumer_web_policyConfig_updateTravelPolicy";
import { ArrayTransferred, StringTransferred } from "@/lib/utils/common.js";
export default {
  name: "evectionAdd",
  data() {
    return {
      activeBtn: null,
      formData: {
        flightPolicy: 1,
        reserveDays: 0,
        beforeAfterTime: 1,
        flightControlRules: 1,
        trainsPolicy: 1,
        theDiscount: 0,
        hotelPolicy: 1,
        cGSeatName: [],
        seatName: [],
        dSeatName: [],
      },
      trainSeatCode: [
        {
          name: "商务座",
          code: "swz",
        },
        {
          name: "一等座",
          code: "ydz",
        },
        {
          name: "二等座",
          code: "edz",
        },
        {
          name: "高级软卧",
          code: "gjrw",
        },
        {
          name: "软卧",
          code: "rw",
        },
        {
          name: "硬卧",
          code: "yw",
        },
        {
          name: "硬座",
          code: "yz",
        },
        {
          name: "软座",
          code: "rz",
        },
        {
          name: "动卧",
          code: "dw",
        },
        {
          name: "无座",
          code: "wz",
        },
        {
          name: "特等座",
          code: "tdz",
        },
      ],
      positionIdList: [],
      airDiscount: [
        {
          value: 0,
          label: "不限",
        },
        {
          value: 3,
          label: "预定3折及以下",
        },
        {
          value: 4,
          label: "预定4折及以下",
        },
        {
          value: 5,
          label: "预定5折及以下",
        },
        {
          value: 6,
          label: "预定6折及以下",
        },
        {
          value: 7,
          label: "预定7折及以下",
        },
        {
          value: 8,
          label: "预定8折及以下",
        },
        {
          value: 9,
          label: "预定9折及以下",
        },
      ],
      beforeAfterTimeOptions: [
        {
          value: 1,
          label: "1小时",
        },
        {
          value: 2,
          label: "2小时",
        },
        {
          value: 3,
          label: "3小时",
        },
        {
          value: 4,
          label: "4小时",
        },
        {
          value: 5,
          label: "5小时",
        },
      ],
      positionVoArr: [],
      type: "",
    };
  },
  created() {},
  mounted() {
    let type = (this.type = this.$route.query.type);
    type === "edit" ? this.getDetail() : this._getPositionVo();
  },
  computed: {
    arrangePositionVo(type) {
      return function(type) {
        return this.getArrangePositionVo(type);
      };
    },
  },
  methods: {
    change_flightPolicy (val) {
      console.log('val', val);
      if (['1', '2'].includes(val.toString()) && this.formData.theDiscount) {
        this.formData.theDiscount = 0;
      }
    },
    /**
     * 更改 职级显示
     */
    getArrangePositionVo(type) {
      return this.positionVoArr.filter((item) => {
        if (type) {
          return item.isEcho;
        } else {
          return !item.isEcho;
        }
      });
    },
    /**
     * 取消
     */
    channelInputLimit(e) {
      let key = e.key;
      if (key === "e" || key === ".") {
        e.returnValue = false;
        return false;
      }
      return true;
    },
    cancel() {
      this.$router.push({ name: "admin-evection-policy" });
    },
    aaa(val) {
      val = val.slice(0, 2);
    },
    /**
     * 获取详情
     */
    getDetail() {
      let detail = JSON.parse(this.$route.query.detail);
      let arr = ["cGSeatName", "dSeatName", "seatName"];
      this._getPositionVo({ id: detail.id });
      StringTransferred(arr, detail);
      this.formData = Object.assign({ ...detail });
    },
    /**
     * 获取职级
     */
    async _getPositionVo(data) {
      let res = await getPositionVo(data);
      this.positionVoArr = res.datas;
    },
    /**
     * 删除 职级
     */
    deletePosition(val) {
      let positionIds = this.formData.positionIds;
      this.positionVoArr.map((item) => {
        item.id === val.id ? (item.isEcho = false) : "";
      });
    },
    /**
     * 验证
     */
    verify({ type, formData }) {
      var state = {
        type: type || "",
        form: formData || this.formData,
        statu: true,
        trainList: [
          "cGSeatName",
          "dSeatName",
          "seatName",
          "trainsControlRules",
        ],
        hotelList: [
          "firstCityRestrictions",
          "secondCityRestrictions",
          "thirdCityRestrictions",
          "elseCityRestrictions",
          "hotelControlRules",
        ],
      };
      var errorMsg = (msg) => {
        state.statu = false;
        this.$message({ type: "warning", message: msg });
        return true;
      };
      var verifyForm = (arr, msg = "") => {
        let _arr = arr;
        _arr.some((item) => {
          if (Array.isArray(state.form[item])) {
            if (state.form[item].length === 0) {
              return errorMsg(msg);
            }
          }
          if (!state.form.hasOwnProperty(item) || !state.form[item]) {
            return errorMsg(msg);
          }
        });
      };

      state.type === "hotel" ? hotel() : state.type === "train" ? train() : "";
      function train() {
        return verifyForm(state.trainList, "请完善火车票政策");
      }
      function hotel() {
        return verifyForm(state.hotelList, "请完善酒店政策");
      }

      return state.statu;
    },
    verificationFormData(_formData) {
      let formData = _formData;
      if (formData.hotelPolicy === 2) {
        return this.verify({ type: "hotel", formData });
      }
      if (formData.trainsPolicy === 2) {
        return this.verify({ type: "train", formData });
      }
      return true;
    },
    /**
     * reset formData
     */
    clearForm({ type, formData }) {
      var state = {
        type: type || "",
        trainList: [
          "cGSeatName",
          "dSeatName",
          "seatName",
          "trainsControlRules",
        ],
        hotelList: [
          "firstCityRestrictions",
          "secondCityRestrictions",
          "thirdCityRestrictions",
          "elseCityRestrictions",
          "hotelControlRules",
        ],
        formData: formData || {},
      };
      var clearList = (arr) => {
        arr.map((name) => {
          formData[name] = "";
        });
      };
      if (type === "hotel") {
        clearList(state.hotelList);
      } else if (type === "train") {
        clearList(state.trainList);
      }
    },
    //添加train code
    getTrainCode(formData) {
      let rule = [
        {
          name: "cGSeatName",
          code: "cGSeatCode",
        },
        {
          name: "dSeatName",
          code: "dSeatCode",
        },
        {
          name: "seatName",
          code: "seatCode",
        },
      ];
      let trainSeatCode = this.trainSeatCode;
      rule.map((item, index) => {
        let arr = [];
        formData[item.name].map((codeName) => {
          trainSeatCode.filter((code) => {
            if (code.name === codeName) {
              arr.push(code.code);
              formData[item.code] = arr.join(",");
            }
          });
        });
      });
    },
    /**
     * 修改 政策
     */
    async _updateTravelPolicy(data) {
      let res = await updateTravelPolicy(data);
      this.$message({ type: "success", message: "修改成功" });
      this.$router.go(-1);
    },
    /**
     * 获取职级 id
     */
    getPositionIds(formData) {
      let positionIds = this.positionVoArr.reduce((newArr, item) => {
        if (item.isEcho) {
          newArr.push(item.id);
        }
        return newArr;
      }, []);
      let arr = formData.positionIds || [];
      formData.positionIds = arr.concat(positionIds);
    },
    /**
     * 保存
     */
    submit() {
      let formData = JSON.parse(JSON.stringify(this.formData));

      this.getTrainCode(formData);
      let type = this.$route.query.type;
      let _arr = ["cGSeatName", "seatName", "dSeatName"];
      this.getPositionIds(formData);
      if (!formData.positionIds || formData.positionIds.length === 0)
        return this.$message({ type: "warning", message: "请选择职级" });
      if (!this.verificationFormData(formData)) return;

      formData.reserveDays = Math.floor(formData.reserveDays);
      ArrayTransferred(_arr, formData);
      if (formData.trainsPolicy !== 2) {
        this.clearForm({ type: "train", formData });
      }
      if (formData.hotelPolicy !== 2) {
        this.clearForm({ type: "hotel", formData });
      }

      type === "edit"
        ? this._updateTravelPolicy(formData)
        : this._addTravelPolicy(formData);
    },

    /**
     * 添加 职级政策
     */
    async _addTravelPolicy(data) {
      let res = await addTravelPolicy(data);
      this.$message({ type: "success", message: "添加成功" });
      this.$router.go(-1);
    },
    /**
     * 新增 职级
     */
    getCheckedNodes(arr) {
      let node = arr.map((id) => {
        return this.positionVoArr.forEach((item) => {
          item.id === id ? (item.isEcho = true) : "";
        });
      });
    },
    getRank(val) {
      this.activeBtn = null;
      let arr = [].concat(...val);
      this.$refs.cascaderAddr.checkedValue = [];
      this.getCheckedNodes(arr);
    },
  },
};
